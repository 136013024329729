<template>
  <div class="container">

    <el-tabs type="border-card" style="margin-top:20px;height: 95vh;">
      <el-tab-pane label="服务器监控">

        <el-tabs type="border-card" v-model="tabName" style="margin-top:20px;height: 85vh;" @tab-click="handleClick">
          <el-tab-pane v-for="item in serverAcounts" :key="item.key" :value="item.label" :name="item.label">
            <span slot="label"><i :id="'tab-' + item.label" class="el-icon-s-platform"></i> {{ item.ip +
              '（' + item.label + '）'
            }}</span>
            <div style="text-align: center;display: flex;justify-content: center;">
              <div class="block">

                查询天数
                <el-slider v-model="step" :step="1" :max="3" show-input @change="handleStep">
                </el-slider>
              </div>
            </div>

            <el-tabs type="border-card" style="margin-top:20px">

              <el-tab-pane label="负载折线走势图">
                <el-card style="margin-top:20px">
                  <div class="charts_stats">
                    <div>
                      <div>
                        <div :id="item.label + 'cpu_load'" class="charts_stats_line"></div>
                      </div>
                    </div>
                    <div style="margin-left:150px">
                      <div>
                        <div :id="item.label + 'memory_load'" class="charts_stats_line"></div>
                      </div>
                    </div>

                  </div>
                </el-card>
              </el-tab-pane>
            </el-tabs>
          </el-tab-pane>
        </el-tabs>
      </el-tab-pane>
      <el-tab-pane label="">
       
      </el-tab-pane>
    </el-tabs>

  </div>
</template>

<script src="https://cdn.jsdelivr.net/npm/echarts-liquidfill@3/dist/echarts-liquidfill.min.js"></script>

<script>
  import * as echarts from 'echarts';
  import 'echarts-liquidfill';
  import requestApi from "@/api/server.js";


  let timerLine;
  let lxTime = 10000;

 let myChart_cpu_load;
 let myChart_memory_load;
 let player=null;
  export default {
    mounted() {
     
    
    },
    created() {
      this.getAccounts();
      this.tabs();
     
    },destroyed(){
      clearInterval(timerLine);
    },
    data() {
      return {
        serverAcounts: [],
        ossTitle: "",
        ossContent: "",
        step:1,
        tabName:"test",
        webRtcServer: null,
        //这里看自己的需要，也可以传入另一台电脑的ip，前提是都得在在一个局域网内
       
      }
    },
    methods: { 
     handleClick(tab, event) {
        this.tabName=tab.name
        this.tabs();
      },
      handleStep(){
       //查询天数事件
       this.tabs();
      },
      timePoll() { 
        timerLine = setInterval(() => {
           this.tabs();
        }, lxTime);
      },
      async getAccounts() {
        let params = {};
        await this.$get(requestApi.getAccounts, params).then((res) => {
          if (res.success && res.statusCode == 200) {
            this.serverAcounts = res.data;
            this.tabs();
          }
        });
      }
      ,tabs() {
        this.serverAcounts.forEach(f => {         
          if(f.label==this.tabName){
              this.drawLineChart(f)
            }
        });
      },
      drawLineChart(row) {
        let env = row.label;
        let params = { env: env,day: this.step };
        this.$get(requestApi.getCpuLineCharts, params).then((res) => {
          if (res.success && res.statusCode == 200) {
            //cpu 实时图
            myChart_cpu_load = echarts.getInstanceByDom(document.getElementById(env + "cpu_load"))
            if (myChart_cpu_load == null) { // 如果不存在，就进行初始化。
              myChart_cpu_load = echarts.init(document.getElementById(env + "cpu_load"));
            }
            let data = res.data;
            let load_option = this.getServerLoadLineOption(data, "Cpu折线走势图");
            myChart_cpu_load.setOption(load_option);
          }
        });

        this.$get(requestApi.getMemoryLineCharts, params).then((res) => {
          if (res.success && res.statusCode == 200) {
            //cpu 实时图
            myChart_memory_load = echarts.getInstanceByDom(document.getElementById(env + "memory_load"))
            if (myChart_memory_load == null) { // 如果不存在，就进行初始化。
              myChart_memory_load = echarts.init(document.getElementById(env + "memory_load"));
            }
            let data = res.data;
            let load_option = this.getServerLoadLineOption(data, "内存折线走势图");
            myChart_memory_load.setOption(load_option);
          }
        });
      } ,
      
      //负载条纹图
      getServerLoadLineOption(data, title) {
        let option = {
          title: {
            text: title,
            left: '1%'
          },
          tooltip: {
            trigger: 'axis'
          },
          grid: {
            left: '5%',
            right: '15%',
            bottom: '10%'
          },
          xAxis: {
            data: data.keys,
          
          },
          yAxis: {},
          toolbox: {
            right: 10,
            feature: {
              dataZoom: {
                yAxisIndex: 'none'
              },
              restore: {},
              saveAsImage: {}
            }
          },
          dataZoom: [
            {
              startValue: data.startValue
            }      
          ]
          ,
          visualMap: {
            top: 50,
            right: 10,
            pieces: [
              {
                gt: 0,
                lte: 30,
                color: '#93CE07'
              },
              {
                gt: 30,
                lte: 40,
                color: '#FBDB0F'
              },
              {
                gt: 40,
                lte: 50,
                color: '#FC7D02'
              },
              {
                gt: 50,
                lte: 70,
                color: '#FD0100'
              },
              {
                gt: 70,
                lte: 80,
                color: '#AA069F'
              },
              {
                gt: 80,
                color: '#AC3B2A'
              }
            ],
            outOfRange: {
              color: '#999'
            }
          },
          series: {
            name: title,
            type: 'line',
            data: data.values                  
          }
        };
        return option;
      }
     
  }
}
</script>


<style scoped>
.container {
  width: 100%;
  height: 100%;
}

.charts_stats {
  width: 100%;
  display: flex;
  justify-content: left;
  text-align: center;

}

.charts_stats_line {
  width: 550px;
  height: 250px;
}

.block {
  width: 350px;
}

.view_monitor {
  float: left;
  width: 100%;
}
</style>

<style scoped>
.main-container {
    display: block;
    width: 1280px;
    margin-left: auto;
    margin-right: auto;
}

.url-input-container {
    display: flex;
}

.url-input-container label {
    display: flex;
    align-items: center;
    font-size: 15px;
}

.url-input-container input {
    flex: auto;
    margin-left: 10px;
    font-size: 15px;
}

.url-input-container button {
    margin-left: 10px;
    font-size: 15px;
}

.video-container {
    margin-top: 20px;
    height: 720px;
}

.video-container video {
    background-color: #000;
    width: 100%;
    height: 100%;
}

.main-container textarea {
    margin-top: 20px;
    width: 100%;
    font-size: 10px;
}
</style>